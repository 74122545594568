<template>
  <g fill="none" fill-rule="evenodd">
    <g :stroke="color" stroke-width="1.5">
      <g stroke="#23303D">
        <path
          d="M21.941 7.617h-6.664c-.169 0-.297-.162-.252-.324.272-.962.295-2.004.295-2.991 0-.545.027-1.162-.034-1.759-.06-.606-.21-1.19-.568-1.65C14.322.38 13.732 0 13.077 0c-1.523 0-1.764 1.492-1.906 2.7-.166 1.41-.232 2.868-.853 4.167-.437.91-1.057 1.692-2.027 2.075-.746.295-1.296.524-2.082.678-.121.022-.209.128-.209.252v9.853c0 .145.115.26.259.26h2.725c.084 0 .16.042.21.108.538.72 1.396 1.189 2.367 1.189h8.54c1.248 0 2.268-1.02 2.268-2.268 0-.322-.078-.626-.216-.897-.054-.108-.027-.238.068-.313.461-.37.761-1.21.761-1.844v-.26c0-.321-.079-.625-.216-.898-.054-.106-.025-.236.067-.313.464-.367.762-.932.762-1.568v-.259c0-.322-.077-.628-.214-.898-.054-.108-.027-.239.067-.313.462-.367.76-.935.76-1.568 0-1.248-1.019-2.266-2.267-2.266M5.293 21H.704C.316 21 0 20.712 0 20.358V8.642C0 8.288.316 8 .704 8h4.59c.39 0 .706.288.706.642v11.716c0 .354-.316.642-.707.642"
          transform="translate(-30 -1747) translate(31 1748)"
        />
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
